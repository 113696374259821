import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from './pages/home/App';
import Post from './pages/post/App';
import Createpage from "./pages/post/Create";

import Fullloading from './widgets/fullloading/App';
import Xphone from "./widgets/xphone/App";

export default function App() {

  const [isloading, setIsLoading] = useState(true);
  const [phone, setPhone] = useState(false);
  const [status, setStatus] = useState(false);

  const callback = (data) => {
    setStatus(true);
    setTimeout(() => {
      setIsLoading(data);
    }, 1000);
  }

  useEffect(() => {
    const handleResize = () => {
      setPhone(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    phone ?
      <Xphone />
      :
      (
        <>
          {(isloading) && <Fullloading status={status} />}
          <Routes>
            <Route path="/" element={<Home callback={callback} />} />
            <Route path="/post" element={<Post />} />
            <Route path="/post/:page?" element={<Createpage />} />
          </Routes>
        </>
      )
  )
}