import { useState, useEffect, useRef } from 'react';
import {
    FaArrowRight,
    FaCoffee,
    FaGithub,
    FaLink,
    FaPaperPlane,
    FaRssSquare,
    FaSteam
} from "react-icons/fa";
import { BsPinFill } from "react-icons/bs";
import styles from './home.module.scss';
import Typed from 'typed.js';

import Matter from '../../widgets/matter/App';
import Clock from '../../widgets/clock/App';
import Weathers from '../../widgets/weathers/App';

export default function Home({ callback }) {

    const [text, setText] = useState('');
    const [userip, setUserIP] = useState('');
    const [steamid] = useState('76561199157728746');
    const [run, setRun] = useState(false);
    const [userdata, setUserData] = useState({});
    const [weatherdata, setWeatherData] = useState('01d');
    const [checked, setChecked] = useState(0);
    const typedRef = useRef(null);

    const color = 'rgb(255, 255, 255)', name = 'OiklM';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const quotePromise = fetch('https://v1.hitokoto.cn/?c=d&charset=utf-8')
                    .then(res => res.json())
                    .then(data => setText(data.hitokoto));

                const ipPromise = fetch('https://api.ipify.org?format=json')
                    .then(res => res.json())
                    .then(ipData => {
                        setUserIP(ipData.ip);
                        return fetch(`https://ipapi.co/${ipData.ip}/json/`);
                    })
                    .then(userRes => userRes.json())
                    .then(userData => setUserData(userData))

                await Promise.all([quotePromise, ipPromise]);
            } catch (err) {
                console.log(err);
            } finally {
                callback(false);
                setRun(true);
            }
        }

        fetchData();
    }, [callback])

    useEffect(() => {
        const fetchWeatherData = async () => {
            if (userdata.latitude && userdata.longitude) {
                try {
                    const weatherData = await fetch(`https://api.oiklm.com/api/weather?lat=${userdata.latitude}&lon=${userdata.longitude}`)
                        .then(res => res.json());
                    setWeatherData(weatherData.weather[0].icon);
                } catch (err) {
                    console.log(err);
                }
            }
        };

        fetchWeatherData();
    }, [userdata.latitude, userdata.longitude]);

    useEffect(() => {
        const current = typedRef.current;

        const typed = new Typed(current, {
            strings: [text],
            typeSpeed: 50,
            loop: false
        })

        return () => {
            typed.destroy();
        }
    }, [text])

    useEffect(() => {
        const ids = [0, 1, 2];

        const interval = setInterval(() => {
            setChecked((prev) => {
                const nextIndex = (ids.indexOf(prev) + 1) % ids.length;
                return ids[nextIndex];
            });
        }, 20000);

        return () => clearInterval(interval);
    }, [checked]);

    const handleCopy = () => {
        navigator.clipboard.writeText(userip);
    }

    const handleChange = (id) => {
        setChecked(checked === id ? null : id);
    };

    return (
        <main className={styles.main}>
            <Matter />
            <div className={styles.profile}>
                <div className={`${styles.about} ${run ? styles.go : ''}`}>
                    <ul className={styles.weather}>
                        <Weathers data={weatherdata} />
                    </ul>
                    <h1>哈囉，我是 <span>Raxytw</span></h1>
                    <span ref={typedRef}></span>
                </div>
                <div className={`${styles.me} ${run ? styles.go : ''}`}>
                    <h3>Solo Developer <span>個人開發者</span></h3>
                    <h1>Raxytw</h1>
                    <span>也可稱 瑞</span>
                    <div className={styles.id}>SteamID : {steamid}</div>
                    <ul className={styles.tech}>
                        <li>
                            <img src="/images/code/DockerLogo.png" alt=" docker" className={styles.docker} />
                            <img src="/images/code/Figma.png" alt="figma" />
                            <img src="/images/code/GitHub.png" alt="github" />
                            <img src="/images/code/Next.js.png" alt="next" />
                            <img src="/images/code/Node.js.png" alt="node" />
                        </li>
                        <li>
                            <img src="/images/code/React.png" alt="react" />
                            <img src="/images/code/Rust.png" alt="rust" />
                            <img src="/images/code/Tailwindcss.png" alt="tailwindcss" />
                            <img src="/images/code/TypeScript.png" alt="ts" />
                            <img src="/images/code/VSCode.png" alt="vscode" className={styles.vscode} />
                        </li>
                    </ul>
                </div>
                <div className={`${styles.post} ${run ? styles.go : ''}`}>
                    <div className={styles.views}>
                        <div className={styles.slider} style={{ transform: `translateX(${-(checked) * 100}%)` }}>
                            <div>
                                <h3><FaRssSquare />最新文章</h3>
                                <p>目前還沒有文章，敬請期待。</p>
                            </div>
                            <div>
                                <h3><FaPaperPlane />最新動態</h3>
                                <p>目前還沒有動態，敬請期待。</p>
                            </div>
                            <div>
                                <h3><FaLink />最新連結</h3>
                                <p>目前還沒有連結，敬請期待。</p>
                            </div>
                        </div>
                    </div>
                    <ul className={styles.switch}>
                        {[0, 1, 2].map((id) => (
                            <label key={id}>
                                <input
                                    type="checkbox"
                                    checked={checked === id}
                                    onChange={() => handleChange(id)}
                                />
                                <span></span>
                            </label>
                        ))}
                    </ul>
                </div>
                <div className={`${styles.link} ${run ? styles.go : ''}`}>
                    <ul>
                        <a href="https://steamcommunity.com/id/oiklM/" target='_blank' rel="noreferrer" className={styles.steam}>
                            <FaSteam />
                            Steam
                        </a>
                        <a href="https://github.com/Raxytw" target='_blank' rel="noreferrer" className={styles.github}>
                            <FaGithub />
                            Github
                        </a>
                        <a href="https://buymeacoffee.com/raxytw" target='_blank' rel="noreferrer" className={styles.cafe}>
                            <FaCoffee />
                            Buy me a Coffee
                        </a>
                    </ul>
                </div>
                <span>&copy; 2024 Raxytw. 保留所有權利。</span>
            </div>
            <div className={styles.widget}>
                <div className={`${styles.clock} ${run ? styles.go : ''}`}>
                    <Clock />
                </div>
                <div className={`${styles.sign} ${run ? styles.go : ''}`}>
                    <img src={`https://jrenc.azurewebsites.net/api/signature?code=zHZRCCItO-yB8t7d2KyitELFDwADnXIotkeeIQL3juyNAzFucnyrWA%3D%3D&name=${name}%20&animate=true&speed=1&color=${color}`} alt="sign" />
                </div>
                <div className={`${styles.mini} ${run ? styles.go : ''}`}>
                    <h3><BsPinFill />小報板</h3>
                    <p className={styles.country}>我來自 <span>Taiwan</span></p>
                    <p className={styles.oc}>是一隻 <span>狐狸魚 <span>FoxFish</span></span></p>
                    <p>歡迎你瀏覽我的網站</p>
                    <br />
                    <p>平時會玩一些遊戲</p>
                    <p>歡迎加我為好友 ( •̀ ω •́ )✧</p>
                    <p className={styles.ip}>你的 IP # <span onClick={handleCopy} title='複製'>{userip}</span></p>
                </div>
                <div className={`${styles.goto} ${run ? styles.go : ''}`}>
                    <h3><FaLink />連結</h3>
                    <ul>
                        <a href="/post" title='我的文章' className={styles.post}>
                            <FaPaperPlane />
                            文章
                            <span><FaArrowRight /></span>
                        </a>
                        <a href="https://api.oiklm.com/rss" title='RSS 訂閱' className={styles.rss}>
                            <FaRssSquare />
                            RSS
                            <span><FaArrowRight /></span>
                        </a>
                    </ul>
                </div>
            </div>
        </main>
    )
}