import { MdSunny } from "react-icons/md";
import { FaCloud, FaCloudMeatball, FaCloudRain, FaCloudShowersHeavy, FaCloudSun, FaWind } from "react-icons/fa";
import { FaCloudBolt } from "react-icons/fa6";

export default function Weathers({ data }) {

    if (!data) return null;
    else if (data === '01d' || data === '01n') {
        return (
            <li>
                <MdSunny />
                太陽
            </li>
        )
    }
    else if (data === '02d' || data === '02n') {
        return (
            <li>
                <FaCloudSun />
                多雲
            </li>
        )
    } else if (data === '04d' || data === '04n') {
        return (
            <li>
                <FaCloud />
                多雲
            </li>
        )
    } else if (data === '09d' || data === '09n') {
        return (
            <li>
                <FaCloudShowersHeavy />
                下雨
            </li>
        )
    } else if (data === '10d' || data === '10n') {
        return (
            <li>
                <FaCloudRain />
                下雨
            </li>
        )
    } else if (data === '11d' || data === '11n') {
        return (
            <li>
                <FaCloudBolt />
                雷雨
            </li>
        )
    } else if (data === '13d' || data === '13n') {
        return (
            <li>
                <FaCloudMeatball />
                下雪
            </li>
        )
    } else if (data === '50d' || data === '50n') {
        return (
            <li>
                <FaWind />
                風大
            </li>
        )
    } else return null;

}