import { useEffect } from 'react'
import styles from './clock.module.css'

export default function Clock() {

    useEffect(() => {
        let size = 32;
        let columns = Array.from(document.getElementsByClassName(styles.column));
        let class_list = [styles.visible, styles.near, styles.far, styles.far, styles.distant, styles.distant];
        let is_24_hour_clock = true;

        function getClock() {
            let d = new Date();
            let hour = is_24_hour_clock ? d.getHours() : d.getHours() % 12 || 12;
            hour = hour < 10 ? '0' + hour : hour;
            let minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
            let second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
            return hour + '' + minute + '' + second;
        }

        function getClass(n, i) {
            return class_list.find(function (_class, class_index) {
                return i - class_index === n || i + class_index === n;
            }) || '';
        }

        setInterval(() => {
            let c = getClock();
            columns.forEach(function (ele, i) {
                let n = parseInt(c[i]);
                let offset = -n * size;
                ele.style.transform = 'translateY(calc(50% + ' + offset + 'px - ' + (size / 2) + 'px))';

                Array.from(ele.children).forEach(function (ele2, i2) {
                    ele2.className = `${styles.num} ` + getClass(n, i2);
                })
            })
        }, 1000);
    })

    return (
        <div className={styles.clock}>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
            </div>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
                <div className={styles.num}>3</div>
                <div className={styles.num}>4</div>
                <div className={styles.num}>5</div>
                <div className={styles.num}>6</div>
                <div className={styles.num}>7</div>
                <div className={styles.num}>8</div>
                <div className={styles.num}>9</div>
            </div>
            <div className={styles.colon}></div>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
                <div className={styles.num}>3</div>
                <div className={styles.num}>4</div>
                <div className={styles.num}>5</div>
            </div>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
                <div className={styles.num}>3</div>
                <div className={styles.num}>4</div>
                <div className={styles.num}>5</div>
                <div className={styles.num}>6</div>
                <div className={styles.num}>7</div>
                <div className={styles.num}>8</div>
                <div className={styles.num}>9</div>
            </div>
            <div className={styles.colon}></div>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
                <div className={styles.num}>3</div>
                <div className={styles.num}>4</div>
                <div className={styles.num}>5</div>
            </div>
            <div className={styles.column}>
                <div className={styles.num}>0</div>
                <div className={styles.num}>1</div>
                <div className={styles.num}>2</div>
                <div className={styles.num}>3</div>
                <div className={styles.num}>4</div>
                <div className={styles.num}>5</div>
                <div className={styles.num}>6</div>
                <div className={styles.num}>7</div>
                <div className={styles.num}>8</div>
                <div className={styles.num}>9</div>
            </div>
        </div>
    )
}