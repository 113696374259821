import {
    Engine,
    Render,
    Runner,
    Common,
    Bodies,
    Composite,
    Mouse,
    MouseConstraint
} from 'matter-js';
import { useEffect, useRef } from "react"
import styles from './matter.module.css';

export default function Matter() {

    const matterRef = useRef(null);

    useEffect(() => {
        const colors = ['#E04214', '#F2BA4B', '#34C5DE', '#0A9F79'];
        const currentref = matterRef.current;

        var engine = Engine.create(),
            world = engine.world;

        var render = Render.create({
            element: currentref,
            engine: engine,
            options: {
                width: currentref.offsetWidth,
                height: currentref.offsetHeight,
                wireframes: false,
                background: 'transparent'
            }
        });

        Render.run(render);

        var runner = Runner.create();
        Runner.run(runner, engine);

        const isMobile = window.innerWidth < 768;

        const numCircles = isMobile ? 20 : 50;
        const xCircles = isMobile ? 35 : 105;

        if (currentref) {
            for (let i = 0; i < numCircles; i++) {
                const radius = Common.random(30, xCircles);
                const colorIndex = i % colors.length;
                const circle = Bodies.circle(Math.random() * currentref.offsetWidth, Math.random() * currentref.offsetWidth, radius, {
                    restitution: 0.8,
                    friction: 0.1,
                    render: {
                        fillStyle: colors[colorIndex],
                    },
                });
                Composite.add(world, circle);
            }

            Composite.add(world, [
                Bodies.rectangle(currentref.offsetWidth / 2, 0, currentref.offsetWidth, 20, {
                    isStatic: true,
                    render: { visible: false }
                }),
                Bodies.rectangle(currentref.offsetWidth / 2, currentref.offsetHeight, currentref.offsetWidth, 20, {
                    isStatic: true,
                    render: { visible: false }
                }),
                Bodies.rectangle(currentref.offsetWidth, currentref.offsetHeight / 2, 20, currentref.offsetHeight, {
                    isStatic: true,
                    render: { visible: false }
                }),
                Bodies.rectangle(0, currentref.offsetHeight / 2, 20, currentref.offsetHeight, {
                    isStatic: true,
                    render: { visible: false }
                }),
            ]);
        }

        const mouse = Mouse.create(render.canvas);
        const mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: { visible: false },
            },
        });

        Composite.add(world, mouseConstraint);
        render.mouse = mouse;

        return () => {
            Render.stop(render);
            Runner.stop(runner);
            Engine.clear(engine);
            Composite.clear(world, false);
            render.canvas.remove();
            render.textures = {};
        };
    }, [])

    return (
        <div ref={matterRef} className={styles.matter}></div>
    )
}