import { MdOutlineWebAssetOff } from "react-icons/md";
import styled from "styled-components";

export default function Xphone() {

    const Main = styled.main`
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
            font-size: 100px;
            color: var(--theme-color-1);
        }

        h2 {
            margin: 5px 0;
        }

        span {
            margin: 5px 0;
        }

        p {
            opacity: .5;
        }
    `

    return (
        <Main>
            <MdOutlineWebAssetOff />
            <h2>暫時不提供以下裝置訪問</h2>
            <span>平板、手機、遊戲機等。</span>
            <p>若想查看，請移至桌面端</p>
        </Main>
    )
}